// Import Firebase
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { getFunctions } from "firebase/functions";

// Your Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDzeP-1lT5ygUDq5fafY_VYcMgBayfQFTQ",
    authDomain: "kait-ai.firebaseapp.com",
    projectId: "kait-ai",
    storageBucket: "kait-ai.appspot.com",
    messagingSenderId: "916262216347",
    appId: "1:916262216347:web:6e47e7b690582cab3b5c3e",
    measurementId: "G-0VHEBM3LXR"
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const functions = getFunctions(app);
export const db = app.firestore();
export const provider = new GoogleAuthProvider();
