/*global chrome*/
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { auth } from "../Firebase/Firebase";
import { onAuthStateChanged } from "firebase/auth";

const Header = () =>{

  const navigate = useNavigate();
  const [isSignedIn, setIsSignedIn] = useState(false)
  const [email, setEmail] = useState('')

  useEffect(() =>{
    onAuthStateChanged(auth, (user) => {
        if (user) {
            console.log("Successful Sign in: ", user.email);
            setEmail(user.email)
            setIsSignedIn(true)
        } else {
            console.log("No User Found/Signed Out");
            setEmail('')
            setIsSignedIn(false)
            // Perform actions for a signed-out user
        }
    });
}, [])

  return(
    <HeaderDiv>
      <KaitLogoDiv onClick={() => navigate('/')}>
        <KaitBlue>K</KaitBlue><KaitGreen>ai</KaitGreen><KaitBlue>t</KaitBlue>
      </KaitLogoDiv>
      <AccountDiv>
        {
          isSignedIn ?
          <GoToAccount>
            <GoToAccountButton onClick={() => navigate('/account')}>Go To Account</GoToAccountButton>
          </GoToAccount>
          :
          <SignIn>
            <SignInButton onClick={() => navigate('/login')}>Log In</SignInButton>
          </SignIn>
        }
      </AccountDiv>
    </HeaderDiv>
  )
}

//Header
const HeaderDiv = styled.div`
  height: 10%;
  padding-top: 5%;
  border-bottom: 10px;
  display: flex; 
  justify-content: space-between;
  height: 25vh;    
`
//KAIT logo
const KaitLogoDiv = styled.div`
  display: flex;
  margin-left: 20%;
`

const KaitBlue = styled.p`
  font-size: 120px;
  color: #6157E1;
`
const KaitGreen = styled.p`
  font-size: 120px;
  color: #4EB5A9;
`

//Account/Login 
const AccountDiv = styled.div`
  margin-right: 20%;
`

const GoToAccount = styled.div`
  float: right;
`

const GoToAccountButton = styled.button`
  background-color: #4EB5A9;
  padding: 20px;
  border-radius: 10px;
  font-size: 26px;
  color: white;
  font-family: 'Nerko One', sans-serif;
`

//Not Logged In, Show the 'Sign In' button
const SignIn = styled.div`
  float: right;
  height: 100%;
`

const SignInButton = styled.button`
  background-color: #4EB5A9;
  padding: 20px;
  border-radius: 10px;
  font-size: 26px;
  color: white;
  font-family: 'Nerko One', sans-serif;
`

export default Header