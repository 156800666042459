import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { httpsCallable } from 'firebase/functions';
import { auth, functions } from '../Firebase/Firebase';

import { useNavigate } from 'react-router-dom'

import { getSubscriptionLevel, getSubscriptionIdByEmail } from './SubStatusCheck';

import styled from "styled-components"
import Footer from "./Footer"

const SubOptions = () =>{

    const [user, setUser] = useState(null);
    const [subLevel, setSubLevel] = useState(null);

    const navigate = useNavigate();

    const RetrieveSubLevel = async (email) =>{
        const subsriberLevel = await getSubscriptionLevel(email)
        setSubLevel(subsriberLevel)
    }

    useEffect(() =>{
        auth.onAuthStateChanged(user => {
            if (user) {
                setUser(user.email)
                RetrieveSubLevel(user.email)
            } else {
                setUser(null)
            }
        });
    }, [])

    //const stripePromise = loadStripe('pk_live_51PuNVjKHrBbpCbDdi2kENDy4IiePWVOggj06ke0v0wx2Rxfe24l0c6A3nkaUqYn3iVK7STYEj0kEqUKA6XjjfDp6009D0kUDM0');
    const stripePromise = loadStripe('pk_test_51PuNVjKHrBbpCbDdBPcfdSjHLAOz4SvQQyyqVmkJNLuJPr7d5rsWduxcBJYrp4T0jcTLCoKTbhRbjYPEstBOIXBL00h3kf9jjs');

    const handleSubscription = async (priceId) => {

        if(!user){
            navigate('/login')
        }else{
            const stripe = await stripePromise;
            const createCheckoutSession = httpsCallable(functions, 'createCheckoutSession');
        
            try {
              const result = await createCheckoutSession({ priceId, email: user.email });
              const { id: sessionId } = result.data;
        
              const { error } = await stripe.redirectToCheckout({ sessionId });
              if (error) {
                console.error('Stripe Checkout error:', error);
              }
            } catch (error) {
              console.error('Error calling Firebase function:', error);
            }
        }
    };

    const handleUpdateSubscription = async (priceId) => {
        const updateSubscription = httpsCallable(functions, 'updateUserSubscription');
    
        let subId; 
    
        await getSubscriptionIdByEmail(user).then(subscriptionId => {
            if (subscriptionId) {
                subId = subscriptionId;
            } else {
                subId = null;
                console.log('No SubscriptionID found for ', user);
            }
        });
    
        try {
            const result = await updateSubscription({ subscriptionId: subId, items: [{ id: priceId }] });
    
            console.log('Subscription updated:', result.data.subscription);
        } catch (error) {
            console.error('Error updating subscription:', error);
        }
    }

    return(
        <SubOptionsDiv>
            
            <SelectPriceDiv>
                <SelectPriceText>Select a subscription plan below. This application was created by a solo developer, so please consider donating ❤️</SelectPriceText>
            </SelectPriceDiv>
            
            <div>
                <p>Sub Level: {subLevel}</p>
            </div>

            <PricingDiv>

                <BasicMemberDiv>
                    <BasicHeaderDiv>
                        <BasicMemberHeader>Basic Member</BasicMemberHeader>
                    </BasicHeaderDiv>
                    <BasicPriveDiv>
                        <BasicMemberPrice>$2.99/m</BasicMemberPrice>
                    </BasicPriveDiv>
                    <BasicFeatureDiv>
                        <BasicMemberFeatures>+ 25 Monthly Searches</BasicMemberFeatures>
                    </BasicFeatureDiv>
                    <BasicButtonDiv>
                        {/* <BasicMemberButton onClick={() => handleSubscription('price_1PuNlbKHrBbpCbDduUigfxeY')}>Subscribe</BasicMemberButton> */}
                        {!subLevel ?
                            <BasicMemberButton onClick={() => handleSubscription('price_1PuNZ2KHrBbpCbDd9NfpCLC7')}>Subscribe</BasicMemberButton>
                        :
                            <BasicMemberButton onClick={() => handleUpdateSubscription('price_1PuNZ2KHrBbpCbDd9NfpCLC7')}>Subscribe</BasicMemberButton>
                        }
                    </BasicButtonDiv>
                </BasicMemberDiv>

                <AdvancedMemberDiv>
                    <AdvancedHeaderDiv>
                        <AdvancedMemberHeader>Advanced Member</AdvancedMemberHeader>
                    </AdvancedHeaderDiv>
                    <AdvancedPriveDiv>
                        <AdvancedMemberPrice>$4.99/m</AdvancedMemberPrice>
                    </AdvancedPriveDiv>
                    <AdvancedFeatureDiv>
                        <AdvancedMemberFeatures>+ 250 Monthly Searches</AdvancedMemberFeatures>
                    </AdvancedFeatureDiv>
                    <AdvancedButtonDiv>
                        {/* <AdvancedMemberButton onClick={() => handleSubscription('price_1PuNmAKHrBbpCbDd0umlxcsk')}>Subscribe</AdvancedMemberButton> */}
                        {!subLevel ?
                            <AdvancedMemberButton onClick={() => handleSubscription('price_1PuNZVKHrBbpCbDd0heDhhWF')}>Subscribe</AdvancedMemberButton>
                        :
                            <AdvancedMemberButton onClick={() => handleUpdateSubscription('price_1PuNZVKHrBbpCbDd0heDhhWF')}>Subscribe</AdvancedMemberButton>
                        }
                    </AdvancedButtonDiv>
                </AdvancedMemberDiv>

                <VIPMemeberDiv>
                    <VIPHeaderDiv>
                        <VIPMemberHeader>VIP Member</VIPMemberHeader>
                    </VIPHeaderDiv>
                    <VIPPriveDiv>
                        <VIPMemberPrice>$9.99/m</VIPMemberPrice>
                    </VIPPriveDiv>
                    <VIPFeatureDiv>
                        <VIPMemberFeatures>Unlimited Monthly Searches</VIPMemberFeatures>
                    </VIPFeatureDiv>
                    <VIPButtonDiv>
                        {/* <VIPMemberButton onClick={() => handleSubscription('price_1PuNnAKHrBbpCbDdcKwDmk72')}>Subscribe</VIPMemberButton> */}
                        {!subLevel ?
                            <VIPMemberButton onClick={() => handleSubscription('price_1PuNawKHrBbpCbDdjZEMurIG')}>Subscribe</VIPMemberButton>
                        :
                            <VIPMemberButton onClick={() => handleUpdateSubscription('price_1PuNawKHrBbpCbDdjZEMurIG')}>Subscribe</VIPMemberButton>
                        }
                    </VIPButtonDiv>
                </VIPMemeberDiv>
            </PricingDiv>

            <CopyrightDiv>
                <Footer />
            </CopyrightDiv>
        </SubOptionsDiv>
    )
}

const SubOptionsDiv = styled.div`
    padding: 0;
    height: 25vh;
`

const PricingDiv = styled.div`
    display: flex;            
    justify-content: center;    
    gap: 10%;    
    margin-top: 10%;   
    margin-bottom: 10%;  
`

//Basic Membership
const BasicMemberDiv = styled.div`
    width: 450px;
    height: 600px;
    background-color: #6157E1;
    border-radius: 15px;
    &:hover {
        background-color: #5044e3;
        width: 500px;
        height: 650px;
        margin-top: -15px;
        margin-left: -15px;
    }
`

    //Basic Header
const BasicHeaderDiv = styled.div`
    height: 25%;
`

const BasicMemberHeader = styled.p`
    color: #4EB5A9;
    font-size: 42px;
    margin-top: 10%;
    text-align: center;
    padding: 10px;
`
    //Basic Member Price
const BasicPriveDiv = styled.div`
    height: 10%;
`

const BasicMemberPrice = styled.p`
    color: white;
    font-size: 42px;
    text-align: center;
`

    //Basic Features
const BasicFeatureDiv = styled.div`
    height: 25%;
`

const BasicMemberFeatures = styled.p`
    color: white;
    font-size: 42px;
    text-align: center;
    padding: 25px;
`

    //Basic Button
const BasicButtonDiv = styled.div`
    height: 25%;
    text-align: center;
    margin-top: 20%;
    padding: 10px;    
`

const BasicMemberButton = styled.button`
    color: white;
    font-size: 38px;
    text-align: center;
    padding: 20px;
    border-radius: 10px;
    background-color: #4EB5A9;
    font-family: 'Nerko One', sans-serif;
`


//Advancd Membership
const AdvancedMemberDiv = styled.div`
    width: 450px;
    height: 600px;
    background-color: #6157E1;
    border-radius: 15px;
    &:hover {
        background-color: #5044e3;
        width: 500px;
        height: 650px;
        margin-top: -15px;
    }
`
    //Advanced Header
const AdvancedHeaderDiv = styled.div`
    height: 25%;
`

const AdvancedMemberHeader = styled.p`
    color: #4EB5A9;
    font-size: 42px;
    margin-top: 10%;
    text-align: center;
    padding: 10px;
`
    //Advanced Member Price
const AdvancedPriveDiv = styled.div`
    height: 10%;
`

const AdvancedMemberPrice = styled.p`
    color: white;
    font-size: 42px;
    text-align: center;
`

    //Advanced Features
const AdvancedFeatureDiv = styled.div`
    height: 25%;
`

const AdvancedMemberFeatures = styled.p`
    color: white;
    font-size: 42px;
    text-align: center;
    padding: 25px;
`

    //Advanced Button
const AdvancedButtonDiv = styled.div`
    margin-top: 20%;
    padding: 10px;    
    height: 25%;
    text-align: center;
`

const AdvancedMemberButton = styled.button`
    color: white;
    font-size: 38px;
    text-align: center;
    padding: 20px;
    border-radius: 10px;
    background-color: #4EB5A9;
    font-family: 'Nerko One', sans-serif;
`


//VIP Membership
const VIPMemeberDiv = styled.div`
    width: 450px;
    height: 600px;
    background-color: #6157E1;
    border-radius: 15px;
    &:hover {
        background-color: #5044e3;
        width: 500px;
        height: 650px;
        margin-top: -15px;
        margin-right: -15px;
    }
`

    //VIP Header
const VIPHeaderDiv = styled.div`
    height: 25%;
`

const VIPMemberHeader = styled.p`
    color: #4EB5A9;
    font-size: 42px;
    margin-top: 10%;
    text-align: center;
    padding: 10px;
`
    //VIP Member Price
const VIPPriveDiv = styled.div`
    height: 10%;
`

const VIPMemberPrice = styled.p`
    color: white;
    font-size: 42px;
    text-align: center;
`

    //Advanced Features
const VIPFeatureDiv = styled.div`
    height: 25%;
`

const VIPMemberFeatures = styled.p`
    color: white;
    font-size: 42px;
    text-align: center;
    padding: 25px;
`

    //Advanced Button
const VIPButtonDiv = styled.div`
    margin-top: 20%;
    padding: 10px;
    text-align: center;
`

const VIPMemberButton = styled.button`
    color: white;
    font-size: 38px;
    text-align: center;
    padding: 20px;
    border-radius: 10px;
    background-color: #4EB5A9;
    font-family: 'Nerko One', sans-serif;
`


//Select Price
const SelectPriceDiv = styled.div`
    margin-top: 10%;
`
const SelectPriceText = styled.p`
    color: white;
    font-size: 38px;
    text-align: center;
`

//Copyright
const CopyrightDiv = styled.div`
    text-align: center;
`



export default SubOptions