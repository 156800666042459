import styled from "styled-components"

const Footer = () =>{
    return(
        <FooterDiv>
            <ReportProblemDiv>
                <CopyrightText>&copy; Copyright 2024 AIKait.com. All rights reserved.</CopyrightText>
                <ReportProblemText href="mailto:aikaithelp@gmail.com">Report a Problem</ReportProblemText>
            </ReportProblemDiv>
        </FooterDiv>
    )
}

//Report a Problem
const FooterDiv = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    height: 20%;
    margin-top: 10%;
`

const CopyrightText = styled.p`
    color: white;
    font-size: 32px;
`

const ReportProblemDiv = styled.div`
    text-align: center;
    margin-top: auto;
`

const ReportProblemText = styled.p`
    color: white;
    margin-bottom: auto;
    font-size: 20px;
    text-decoration: underline;
`

export default Footer