import styled, { createGlobalStyle } from "styled-components";
import Header from "./Header"
import Footer from "./Footer";

const CancelPage = () =>{
    return (
        <CancelPageDiv>
            <GlobalStyle />
            <CancelPageHeader>
                <Header />
            </CancelPageHeader>
            <CancelPageBody>
                <CancelText>Something went wrong, please go back home and try again.</CancelText>
            </CancelPageBody>
            <CancelPageFooter>
                <Footer />
            </CancelPageFooter>
        </CancelPageDiv>
    )
}


const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Nerko One', sans-serif;
    background-color: #2F284F;
  }
`;

const CancelPageDiv = styled.div`

`
//Header
const CancelPageHeader = styled.div`

`

//Body
const CancelPageBody = styled.div`
    height: 30vh;
`
const CancelText = styled.div`
    color: white;
    font-size: 48px;
   text-align: center;
`

//Footer
const CancelPageFooter = styled.div`

`

export default CancelPage