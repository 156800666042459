import styled, { createGlobalStyle } from "styled-components";
import Header from "./Header"
import Footer from "./Footer";

const SuccessPage = () =>{
    return (
        <SuccessPageDiv>
            <GlobalStyle />
            <SuccessPageHeader>
                <Header />
            </SuccessPageHeader>
            <SuccessPageBody>
                <SuccessText>Congratulations! Your subscription was successful!</SuccessText>
            </SuccessPageBody>
            <SuccessPageFooter>
                <Footer />
            </SuccessPageFooter>
        </SuccessPageDiv>
    )
}


const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Nerko One', sans-serif;
    background-color: #2F284F;
  }
`;

const SuccessPageDiv = styled.div`

`

//Header
const SuccessPageHeader = styled.div`

`
//Body
const SuccessPageBody = styled.div`
    height: 30vh;
`

const SuccessText = styled.div`
    color: white;
    font-size: 48px;
    text-align: center;
`

//Footer
const SuccessPageFooter = styled.div`

`


export default SuccessPage